import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import moment from "moment"

import "./footer.css"
import Logo from "./../assets/logo/logo.svg"
import TwitterIcon from "../assets/icons/svg/twitter.svg"

const Footer = ({ siteTitle }) => {
  const footerMenu = [
    {
      title: "Product",
      items: [
        {
          name: "Website monitoring",
          link: "/product/website-monitoring/",
          externalLink: false,
          id: "",
        },
        {
          name: "Status Page",
          link: "/product/status-page/",
          externalLink: false,
          id: "",
        },
        {
          name: "SSL Monitoring",
          link: "/product/ssl-monitoring/",
          externalLink: false,
          id: "",
        },
        {
          name: "Incident Management",
          link: "/product/incident-management/",
          externalLink: false,
          id: "",
        },
        {
          name: "Integrations",
          link: "/integrations/",
          externalLink: false,
          id: "",
        },
        {
          name: "Pricing",
          link: "/pricing/",
          externalLink: false,
          id: "",
        },
      ],
    },
    {
      title: "Company",
      items: [
        {
          name: "Terms of use",
          link: "/terms/",
          externalLink: false,
          id: "",
        },
        {
          name: "Privacy policy",
          link: "/privacy/",
          externalLink: false,
          id: "",
        },
        {
          name: "Our climate pledge",
          link: "https://climate.stripe.com/XKaM6G",
          externalLink: true,
          id: "",
        },
        {
          name: "Support",
          link: "mailto:support@odown.io",
          externalLink: true,
          id: "",
        },
        {
          name: "Twitter @Odown_io",
          link: "https://twitter.com/Odown_io",
          externalLink: true,
          id: "",
        },
      ],
    },
    {
      title: "Resources",
      items: [
        {
          name: "Blog",
          link: "/blog",
          externalLink: false,
          id: "",
        },
        {
          name: "Docs",
          link: "/docs",
          externalLink: false,
          id: "",
        },
        {
          name: "Alternatives",
          link: "/alternatives/",
          externalLink: false,
          id: "",
        },
      ],
    },
    {
      title: "Free Tools",
      items: [
        {
          name: "Website Uptime",
          link: "/free-tools/website-uptime/",
          externalLink: false,
          id: "",
        },
        {
          name: "SSL Certificate Checker",
          link: "/free-tools/ssl-checker/",
          externalLink: false,
          id: "",
        },
      ],
    },
  ]

  const isBlogPost = () => {
    const regex = new RegExp("/blog/[a-zA-Z0-9-]+", "g")
    const current_link = window.location.pathname
    console.log("Current link : ", current_link)
    console.log("regex.test(current_link) : ", regex.test(current_link))
    return regex.test(current_link)
  }

  return (
    <footer>
      <div className="container">
        <div className="row">
          {footerMenu.map((col, index) => (
            <div key={index} className="col">
              <h6 className="footer_menu-header">{col.title}</h6>
              {typeof col.items !== "undefined" &&
                col.items !== null &&
                col.items.length > 0 && (
                  <ul className="footer_menu-content">
                    {col.items.map((item, indexItem) => (
                      <li key={indexItem}>
                        {item.externalLink === true ? (
                          <a href={item.link} target="_blanc">
                            {item.name}
                          </a>
                        ) : (
                          <Link to={item.link}>{item.name}</Link>
                        )}
                      </li>
                    ))}
                    {index === 0 && (
                      <li>
                        <a href="https://status.odown.io">Status</a>
                        <span
                          id="footer-status-indicator"
                          className="dot dot--green"
                        ></span>
                      </li>
                    )}
                    {/* {
								index === 3 && 
								<li>
									<div id="ss_survey_widget" ></div>
								</li>
							}	 */}
                  </ul>
                )}
            </div>
          ))}
        </div>
        <div className="row footer-bottom">
          <div className="col float-left">
            <div className="footer-logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="copyright">
              <span>
                Copyright © {moment().format("Y")} Odown. All rights reserved.
              </span>
            </div>
          </div>
          <div className="col float-right">
            <ul>
              <li>
                <a
                  href="https://twitter.com/Odown_io"
                  target="_blanc"
                  aria-label="Odown Twitter account"
                >
                  <TwitterIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* {
			isBlogPost && 
			<Helmet>
				<script>{`(function(){var e="ss-widget",t="script",a=document,r=window;var s,n,c;r.SS_WIDGET_TOKEN="tt-f44077";r.SS_ACCOUNT="odown.surveysparrow.com";r.SS_SURVEY_NAME="Report-a-bug";if(!a.getElementById(e)){var S=function(){S.update(arguments)};S.args=[];S.update=function(e){S.args.push(e)};r.SparrowLauncher=S;s=a.getElementsByTagName(t);c=s[s.length-1];n=a.createElement(t);n.type="text/javascript";n.async=!0;n.id=e;n.src=["https://","odown.surveysparrow.com/widget/",r.SS_WIDGET_TOKEN].join("");c.parentNode.insertBefore(n,c)}})();`}</script>
			</Helmet>
		} */}
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
