import React from "react"
import "rc-tooltip/assets/bootstrap.css"
import Layout from "./../components/layout"
import SEO from "./../components/seo"

const PageNotFound = () => {
  return (
    <Layout>
      <SEO title="404 Page Not found" />
      <div className="page-not-found-wrapper">
        <div className="container">
          <h1>404</h1>
          <h2>Whoops, that page does not exist!</h2>
          <p>
            Are you looking for the dashboard?{" "}
            <a href="https://app.odown.io">Click here!</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
